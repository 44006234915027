@import "../../../scss/custom-variables.scss";

.Header {
    &.fixed-top {
        height: $height-header;
        width: calc(100% - 14rem);
        left: 14rem;
    }

    &.Header--SidebarCollapsed {
        width: calc(100% - 58px);
        left: 58px;
    }
}
