.MainWrapper {
    width: calc(100vw - 14rem);
    position: relative;
    left: 14rem;

    &--SidebarCollapsed {
        width: calc(100vw - 58px);
        left: 58px;
    
    }

    .Content {
        padding-top: 48px;
    }
}