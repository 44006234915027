@import '../../../scss/custom-variables.scss';

.SidebarMenu {
    width: 14rem;
    height: 100vh;
    padding: 0 0 1rem 0;
    transition: 0.15s all ease-out;
    position: fixed;
    z-index: 1;
    overflow-y: auto;

    &--isCollapsed {
        width: 58px;
    }

    .sidebar-brand {
        height: $height-header;
        text-decoration: none;
        font-size: 1rem;
        font-weight: 800;
        padding: 0 1rem;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: .05rem;
        z-index: 1;
    }

    .nav-item {
        border-bottom: 1px solid rgba(255,255,255, 0.4);
    }

    .navbar-nav .nav-link {
        color: rgba(255,255,255,.7);
        padding-right:1rem;
        padding-left: 1rem;
        font-size: .85rem;

        &:hover {
            color: rgba(255,255,255,1);
        }
    }

    .nav-link.active {
        background: $lighter-blue;
    }

    .nav-link.active,
    .nav-link.active:hover {
        color: $primary;
    }

    &__Toggle {
        color: rgba(255,255,255,0.7);
        &:hover {
            color: rgba(255,255,255,1);
        }
    }
}