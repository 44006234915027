// .PatientsColTreatments {
//     height: calc(100vh - 72px);
// }

.DayHeader {
    height: 72px;
}

.InitialDateInput {
    margin-left: 180px;
}

.TreatmentsPatientsList .list-group {
    // max-height: calc(100vh - 352px);
    height: calc(100vh - 280px);
    overflow: auto;
}