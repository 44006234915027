.ReactModal__Overlay {
    z-index: 9999;
}

.ReactModal__Content {
    border: none  !important;
    background: transparent !important;
}

.ModalOverlay {
    transition: 0.2s ease-out opacity;
    opacity: 0;
    position: fixed;
    inset: 0px;

    &--Active {
        opacity: 1;
    }
}