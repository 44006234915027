.ButtonAdder:hover button,
.ButtonAdder:hover button.text-primary {
    display: inline-block;
    color: white !important;
}

.ButtonAdder:hover input,
.ButtonAdder:active input {
    color: white !important;
    background-color: transparent !important;
}