@font-face {
    font-family: 'dualblist';
    src: url("./icons-wordpress/dualblist.eot?b1m3wi");
    src: url("./icons-wordpress/dualblist.eot?b1m3wi#iefix")
    format("embedded-opentype"),
    url("./icons-wordpress/dualblist.ttf?b1m3wi")
    format("truetype"),
    url("./icons-wordpress/dualblist.woff?b1m3wi")
    format("woff"),
    url("./icons-wordpress/dualblist.svg?b1m3wi#dualblist")
    format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'dualblist' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-quote-close:before {
  content: '\e917';
}

.icon-quote-open:before {
  content: '\e918';
}

.icon-check_thin:before {
  content: '\e919';
}

.icon-close:before {
  content: '\e915';
}

.icon-home:before {
  content: '\e916';
}

.icon-burguer:before {
  content: '\e913';
}

.icon-user:before {
  content: '\e914';
}

.icon-arrow-circle-left:before,
.btn-arrow.arrow-prev:before,
.carousel-col .arrow-prev.slick-arrow:before,
.pagination > li:first-child a[rel='prev']:before,
.pagination > li:first-child span.page-link:before,
.pagination .page-item:first-child a[rel='prev']:before,
.pagination .page-item:first-child span.page-link:before {
  content: '\e900';
}

.icon-arrow-circle-right:before,
.btn-arrow.arrow-next:before,
.carousel-col .arrow-next.slick-arrow:before,
.pagination > li:last-child a[rel='next']:before,
.pagination > li:last-child span.page-link:before,
.pagination .page-item:last-child a[rel='next']:before,
.pagination .page-item:last-child span.page-link:before {
  content: '\e901';
}

.icon-caret-down:before {
  content: '\e902';
}

.icon-caret-left:before {
  content: '\e903';
}

.icon-caret-right:before {
  content: '\e904';
}

.icon-caret-up:before {
  content: '\e905';
}

.icon-check:before,
.check-list > li:before,
.check-list--dark > li:before {
  content: '\e906';
}

.icon-chevron-down:before {
  content: '\e907';
}

.icon-chevron-left:before {
  content: '\e908';
}

.icon-chevron-right:before {
  content: '\e909';
}

.icon-chevron-up:before {
  content: '\e90a';
}

.icon-clock:before {
  content: '\e90b';
}

.icon-close-circle:before {
  content: '\e90c';
}

.icon-dots-h:before,
.dots-top-left:before,
.dots-top-center:before,
.dots-top-right:before,
.dots-bottom-left:before,
.dots-bottom-center:before,
.dots-bottom-right:before {
  content: '\e90d';
}

.icon-dots-v:before,
.dots-side-left:before,
.dots-side-right:before {
  content: '\e90e';
}

.icon-email:before {
  content: '\e90f';
}

.icon-instagram:before {
  content: '\e910';
}

.icon-linkedin:before {
  content: '\e911';
}

.icon-trashcan:before {
  content: '\e912';
}

.dots-side-left,
.dots-side-right,
.dots-top-left,
.dots-top-center,
.dots-top-right,
.dots-bottom-left,
.dots-bottom-center,
.dots-bottom-right {
  position: relative;
  z-index: 1;
}

.dots-side-left:before,
.dots-side-right:before,
.dots-top-left:before,
.dots-top-center:before,
.dots-top-right:before,
.dots-bottom-left:before,
.dots-bottom-center:before,
.dots-bottom-right:before {
  position: absolute;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  font-family: "dualblist";
  color: #009EE2;
  font-size: 0.6em;
  line-height: 1.8;
}

@media (max-width: 839px) {

  .dots-side-left:before,
  .dots-side-right:before,
  .dots-top-left:before,
  .dots-top-center:before,
  .dots-top-right:before,
  .dots-bottom-left:before,
  .dots-bottom-center:before,
  .dots-bottom-right:before {
    font-size: 0.8em;
  }
}

@media (max-width: 479px) {

  .dots-side-left:before,
  .dots-side-right:before,
  .dots-top-left:before,
  .dots-top-center:before,
  .dots-top-right:before,
  .dots-bottom-left:before,
  .dots-bottom-center:before,
  .dots-bottom-right:before {
    font-size: 1em;
  }
}

.side-left {
  padding-left: 36px;
}

@media (max-width: 479px) {
  .side-left {
    padding-left: 30px;
  }
}

.dots-side-left:before {
  top: 0;
  left: -36px;
}

@media (max-width: 479px) {
  .dots-side-left:before {
    left: -30px;
  }
}

.side-right {
  padding-right: 36px;
}

@media (max-width: 479px) {
  .side-right {
    padding-right: 30px;
  }
}

.dots-side-right:before {
  top: 0;
  right: -36px;
}

@media (max-width: 479px) {
  .dots-side-right:before {
    right: -30px;
  }
}

.dots-top-left {
  padding-top: 36px;
}

@media (max-width: 479px) {
  .dots-top-left {
    padding-top: 22px;
  }
}

.dots-top-left:before {
  top: -10px;
  left: 0;
}

@media (max-width: 479px) {
  .dots-top-left:before {
    top: -20px;
  }
}

.dots-top-center {
  padding-top: 36px;
}

@media (max-width: 479px) {
  .dots-top-center {
    padding-top: 22px;
  }
}

.dots-top-center:before {
  top: -10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media (max-width: 479px) {
  .dots-top-center:before {
    top: -20px;
  }
}

.dots-top-right {
  padding-top: 36px;
}

@media (max-width: 479px) {
  .dots-top-right {
    padding-top: 22px;
  }
}

.dots-top-right:before {
  top: -10px;
  right: 0;
}

@media (max-width: 479px) {
  .dots-top-right:before {
    top: -20px;
  }
}

.dots-bottom-left {
  padding-bottom: 36px;
}

@media (max-width: 479px) {
  .dots-bottom-left {
    padding-bottom: 22px;
  }
}

.dots-bottom-left:before {
  bottom: -10px;
  left: 0;
}

@media (max-width: 479px) {
  .dots-bottom-left:before {
    bottom: -20px;
  }
}

.dots-bottom-center {
  padding-bottom: 36px;
}

@media (max-width: 479px) {
  .dots-bottom-center {
    padding-bottom: 22px;
  }
}

.dots-bottom-center:before {
  bottom: -10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media (max-width: 479px) {
  .dots-bottom-center:before {
    bottom: -20px;
  }
}

.dots-bottom-right {
  padding-bottom: 36px;
}

@media (max-width: 479px) {
  .dots-bottom-right {
    padding-bottom: 22px;
  }
}

.dots-bottom-right:before {
  bottom: -10px;
  right: 0;
}

@media (max-width: 479px) {
  .dots-bottom-right:before {
    bottom: -20px;
  }
}
