$widthPatientsCol: 200px;

.Treatments {
    &__PatientsContainer {
        width: $widthPatientsCol;
    }

    &__PosologiesContainer {
        width: calc(100% - #{$widthPatientsCol});
    }
}