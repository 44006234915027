// Customize Bootstrap
@import './customize-bootstrap-timedi.scss';

/*!
 * Bootstrap v5.0.0-beta2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities/api";

// Customize - 2nd step - Add css vars related changes
$font-family-base: 'Lato', sans-serif;
$font-family-header: 'Zilla Slab', serif;

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
// This file is from a new version of bootstrap
@import "./offset.scss";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack

body {
  overflow-x: hidden;
}

.bg-gradient-secondary {
  background-color: $secondary;
  background-image: linear-gradient(180deg, $secondary 10%, $secondary-brighter 100%);
  background-size: cover;
}

.font-header {
  // font-family: $font-family-header;
}

// ICONS FROM WORDPRESS
@import './icons-wordpress.scss';

// custom classes
.cursor-pointer {
  cursor: pointer;
}

.fwp::before { // this overwrites position on icons from wp font
  position: relative;
  left: 0;
  margin-left: -8px;
}

.Toastify__toast-container {
  z-index: 999999999 !important;
}

.btn > span {
  vertical-align: inherit;
}

.btn.btn-sm {
  .material-icons,
  .material-icons-outlined {
    font-size: 1rem;
  }
}

.btn.btn-lg {
  .material-icons,
  .material-icons-outlined {
    font-size: 2rem;
  }
}

.btn-outline-secondary:hover,
.btn-secondary,
.btn-secondary:hover {
  color: white;
}

// FORM custom
.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: $input-border-color;
  background-image: none;
}

.DayPickerInput {
  width: 100%;
}

.orange {
  color: #ff9900;
}

.bg-orange-light {
  background-color: $orange-100;
}

.bg-light-grey {
  background-color: $gray-200;
}

.border-orange {
  border-color: $orange;
}

.ToggleButton input {
  position: absolute;
  clip: rect(0,0,0,0);
  pointer-events: none;
}

.modal .close {
  border: 0;
  background: transparent;
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .25em .25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;

  span { display: none }
}

.even {
  background-color: $gray-200;
  border: 1px solid $gray-300;
}

.odd {
    background-color: $gray-500;
    border: 1px solid $gray-600;
}

// $utilities: (
//   "opacity": (
//     property: opacity,
//     responsive: true,
//     values: (
//       0: 0,
//       25: .25,
//       50: .5,
//       75: .75,
//       100: 1,
//     )
//   )
// );

.opacity-50 {
  opacity: 50%;
}

.onHover:hover {
  background-color: rgba(0,0,0,0.033);
}

.inputNoArrows::-webkit-outer-spin-button,
.inputNoArrows::-webkit-inner-spin-button {
  display: none;
}

.has-changes {
  background-color: $red-100;
}

.bg-previous-treatment {
  background-color: #0078ac !important;
  // background-color: #009ee2 !important // normal posology color
}

.bg-future-treatment {
  // background-color: #63eaff !important
  //background-color: #009ee2 !important // normal posology color
  background-color: #24B6F5 !important;
}

.scrollerHor::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scrollerHor::-webkit-scrollbar:horizontal {
    height: 11px;
}

.scrollerHor::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
}

.InputFileCustom,
.InputFileCustom::-webkit-file-upload-button { /* chromes and blink button */
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.message-left {
  border-top: 1px solid rgba(0,0,0,0.125);
  border-radius: 4px;
  width: 80%;
}

.message {
  width: 80%;
  display: flex;
  border-radius: 4px !important;
  margin-bottom: 8px;
  flex-direction: column;
  border-top: 1px solid rgba(0,0,0,0.125) !important;
}

.message-right {
  left: 20%;
  position: relative;
  align-items: flex-end;
}

.showOnHover button {
  display: none;
}

.showOnHover:hover button {
  display: block;
}

.modal-dialog .modal-header .close {
  margin-left: auto;
}

.form-label-sm {
  font-size: 0.875rem;
}